import React from 'react';
import { Image } from 'react-bootstrap';
import {
  content,
  image,
} from '../komponents/Terms/TermsAndConditions.module.scss';
import handshake from '../../static/images/handshake-terms-and-conditions.jpg';
import termsAndConditions from '../../static/images/terminos-y-condiciones.jpg';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';

function TermsAndConditions() {
  return (
    <>
      <Header />
      <div className={content}>
        <h1>Términos y condiciones</h1>
        <br />
        <Image
          className={image}
          src={termsAndConditions}
          alt="Apretón de manos"
          title="Apretón de manos"
          rounded
        />

        <h2>Acuerdo</h2>
        <p>
          Al usar nuestra página web y servicio tu esta aceptando nuestros
          términos y condiciones. El servicio no podrá ser usado si tu está en
          desacuerdo con ello. Los términos y condiciones nos ayudan a brindarle
          un mejor servicio de comparación, de esta manera tu nos autoriza el
          uso de sus datos sin fines de lucro.
        </p>
        <h2>Nuestro servicio</h2>
        <p>
          Nosotros estamos ofreciendo un servicio de comparación para contratos
          de puntos terminales, con el fin de que la empresa usuaria ahorre
          tiempo y dinero. Nosotros damos información detallada de las
          diferentes Terminales Punto de Venta, así como de los acuerdos de
          canje y contratos disponibles en el mercado mexicano.
          <br />
          <br />
          Nosotros presentamos una cifra aproximada del porcentaje aproximado
          que se puede ahorrar al cambiar de proveedor, terminal y contrato de
          Terminal Punto de Venta, esta cifra está basada en el data que el
          usuario proporciona en forma de factura o llenado de formulario.
          <br />
          <br />
          Nuestros socios tienen contratos con nosotros para representar los
          precios ofrecidos en la página, tome en cuenta que estos pueden tener
          ligeras variaciones de acuerdo a las necesidades de tu empresa, si tu
          recibes información diferente, por favor contáctenos.
          <br />
          <br />
          Nuestros socios pueden obtener la información proporcionada por el
          usuario, esta información nos ayuda a realizar la comparación y a
          brindar un servicio más personalizado.
          <br />
          <br />
          El costo para los contratos de las terminales puede variar si la
          cantidad de transacciones o el valor de ellos cambia, o bien si el
          distribuidor cuenta con promociones temporales.
          <br />
          <br />
          El servicio de comparación está cubriendo la mayoría de las compras
          con tarjetas de crédito y débito, pero algunos tipos de tarjetas no
          están incluidas; eso aplica más para tarjetas del extranjero.
          <br />
          <br />
          Proveemos a nuestros socios de términos y condiciones específicos que
          son aplicables para el uso del comparador de Terminales Punto de
          Venta.
          <br />
          <br />
          Cuando has llenado nuestro formulario de contacto has aceptado que te
          enviamos un correo electrónico de verificación y noticias sobre las
          ofertas disponibles.
          <br />
          <br />
          Al elegir una oferta con PuntoTerminal se te enviará un informe de
          verificación por correo electrónico, así como actualizaciones sobre el
          estatus de tu aplicación.
        </p>

        <h2>La empresa</h2>
        <p>
          PuntoTerminal es el nombre oficial y registrado que usa la empresa.
          <br />
          <br />
          El comparador es completamente independiente de los proveedores de
          Terminales Punto de Venta y siempre presentamos las mejores ofertas en
          orden de conveniencia o comenzando por el precio más bajo.
          <br />
          <br />
          Punto terminal es financiado por nuestros socios, los proveedores que
          venden las terminales y los contratos son ajenos a ello.
          <br />
          <br />
          La empresa se reserva el derecho de revocar o cambiar el servicio que
          brindamos en nuestra página web sin previo aviso.
          <br />
          <br />
          Para ti el resultado que obtiene al usar nuestro comparador es el
          mismo o mejor que si hubiera invertido varias horas o días llamando e
          investigando a todos los proveedores de Terminales Punto de Venta
          existentes en México.
        </p>

        <h2>Sus responsabilidades</h2>
        <p>
          Tu eres responsable de la información que expongas o proporcionas a
          PuntoTerminal. Los contratos son entre el proveedor y el usuario de la
          página, no con PuntoTerminal. Es tu responsabilidad controlar que
          hayas entendido las condiciones de uso del proveedor.
        </p>

        <h2>Inconvenientes con el servicio</h2>
        <p>
          Si tiene algúns inconveniente o sugerencia en cuanto a nuestro
          servicio de comparación, envía un{' '}
          <a href="mailto:info@puntoterminal.mx">correo electronico</a>.
          Nosotros evaluaremos la situación y tomaremos las medidas necesarias
          para mejorar nuestro servicio.
        </p>

        <h2>Nuestra responsabilidad</h2>
        <p>
          No somos responsables de:
          <br />
          <br />
          Posibles inconvenientes que se presenten como consecuencia de una mala
          proporción de datos personales a cerca de tu empresa.
          <br />
          <br />
          Posibles pérdidas por no poder usar nuestro servicio, ya sea por
          problemas en la función o el estatus de la página.
          <br />
          <br />
          Pérdidas por mala interpretación de los comentarios o publicaciones en
          PuntoTerminal.mx.
          <br />
          <br />
          Posibles pérdidas por algún inconveniente técnico ya sea virus o otro
          tipo de código técnicamente dañino para tu equipo de cómputo y/o
          programas.
          <br />
          <br />
          Posibles pérdidas por inconvenientes con sitios web terceros, ya sea
          con políticas y prácticas de privacidad, pérdida de información
          personal y cualquier acceso no autorizado.
        </p>

        <h2>Acceso a nuestra página web</h2>
        <Image
          className={image}
          src={handshake}
          alt="Apretón de manos"
          title="Apretón de manos"
          rounded
        />

        <p>
          Tenemos derecho a restringir el acceso, deshabilitar o eliminar el
          acceso de cualquier usuario en cualquier momento, incluso es aplicable
          con usuarios ya registrados.
          <br />
          <br />
          Tu eres responsable del número de usuario, contraseña e información
          personal asignada por el servidor, es tu responsabilidad tratar dicha
          información con confidencialidad.
          <br />
          <br />
          Tu eres responsable de todos los cambios realizados por medio de tu
          usuario, incluso si el personal de tu empresa tiene acceso a dicho
          usuario.
        </p>

        <h2>Uso</h2>
        <p>
          PuntoTerminal está destinado únicamente para uso empresarial, por lo
          que cada socio y proveedor es responsable de sus propios servicios y
          productos.
          <br />
          <br />
          Al no ser de uso comercial PuntoTerminal se reserva el derecho de
          emprender acciones legales contra cualquier persona o empresa que haga
          uso indebido de nuestro sitio web.
        </p>

        <h2>Derechos de propiedad</h2>
        <p>
          Somos el propietario de todos los derechos intelectuales en nuestro
          sitio web y en el material publicado en él. Esto incluye logotipos,
          nombres comerciales, derechos de diseño, derechos de bases de datos,
          derechos de autor y otros derechos de propiedad relacionados con
          nuestro sitio web.
        </p>

        <h2>Información proporcionada</h2>
        <p>
          La información proporcionada por PuntoTerminal y materiales publicados
          en el sitio web no son material en el cual depositar tu confianza ante
          las elecciones personales de tu negocio. Por lo tanto, renunciamos a
          toda responsabilidad y evento que surja debido a la dependencia de
          dicho material.
        </p>

        <h2>Virus, piratería y otros delitos</h2>
        <p>
          Los usuarios o personas externas al dominio del sitio web no deben
          hacer mal uso introduciendo algún material dañino para el sitio web.
          <br />
          <br />
          No deben intentar lucrar con la actividad empresarial de
          PuntoTerminal, ni atacar el servicio proporcionado.
        </p>

        <h2>Protección de Datos</h2>
        <p>
          Al proporcionarnos sus datos tu estás de acuerdo con la divulgación de
          dicha información, única y exclusivamente para fines empresariales.
          Nos tomamos muy en serio la privacidad y seguridad de tu información
          personal en nuestro sitio web.
          <br />
          PuntoTerminal se toma de manera seria el uso y protección de sus datos
          personales, por lo cual nos comprometemos a no divulgar dicha
          información a otras partes sin tu permiso. Al proporcionarnos tu
          información tu aceptas que esta sea enviada única y exclusivamente a
          proveedores.
          <br />
          <br />
          Salvo lo establecido en esta Política de privacidad, no divulgaremos
          tu información a otras partes sin tu permiso, a menos que estemos
          legalmente obligados a hacerlo. Al enviar información a nuestro sitio
          web, tu aceptas el procesamiento de esta información sobre tu empresa
          por parte nuestra y de terceros proveedores seleccionados.
          <br />
          <br />
          PuntoTerminal puede hacer uso de sus datos para mantenerlo informado
          acerca del estatus de tu petición, ya sea por medio de correo
          electrónico, SMS, número telefónico o correo.
          <br />
          <br />
          Si no estas de acuerdo con la información proporcionada anteriormente
          puede desactivar o eliminar tu información del sitio web.{' '}
        </p>

        <h2>Nuestro uso de cookies</h2>
        <p>
          Los cookies relacionados a nuestro sitio web nos ayudan a brindarte un
          mejor servicio, de esta manera podemos almacenar información
          particular sobre tu uso.{' '}
        </p>
      </div>
      <Footer />
    </>
  );
}

export default TermsAndConditions;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Términos y condiciones PuntoTerminal" />
    <title>Términos y condiciones - PuntoTerminal.mx</title>
  </>
);
